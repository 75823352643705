<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="10" sm="10">
        <va-form>
          <va-text-input
            v-model="announcement.title"
            label="Заголовок"
          ></va-text-input>
          <h3>Данные по объявлению</h3>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="selectedSection.label"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="announcement.technicianId"
                :items="employment"
                label="Управляемая техника"
                chips
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="announcement.rightId"
                :items="right"
                chips
                label="Категория прав"
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="selectedWorkExperience"
                :items="workExperience"
                chips
                label="Опыт работы"
                item-text="title"
                return-object
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="announcement.availabilityOfEquipment"
                :items="vailability"
                chips
                label="Наличие техники"
                item-text="title"
                item-value="bool"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="announcement.positionId"
                :items="position"
                chips
                label="Ваша позиция"
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="announcement.employmentId"
                :items="employment"
                chips
                label="Тип занятости"
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <v-textarea
                v-model="announcement.description"
                outlined
                name="input-7-1"
                label="Описание"
              ></v-textarea>
            </v-col>
          </v-row>

          <h3 v-if="filters.length">Технические характеристики</h3>
          <v-row>
            <v-col v-for="filter in filters" :key="filter.id" cols="12" sm="4">
              <h4>{{ filter.title }}</h4>
              <v-select
                v-model="announcement.filtersCustom[filter.id]"
                :items="filter.elements"
                item-value="id"
                item-text="value"
              ></v-select>
            </v-col>
          </v-row>

          <h3>Опыт работы</h3>
          <v-row v-for="(item, index) in announcement.experience" :key="index">
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="item.company"
                label="Название компании"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="item.position"
                label="Ваша позиция"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="item.workPeriod"
                label="Период работы"
                type="text"
                v-mask="'####-##-##  ####-##-##'"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                v-model="item.employmentId"
                :items="employment"
                chips
                label="Тип занятости"
                item-text="title"
                item-value="id"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                v-model="item.description"
                outlined
                name="input-7-1"
                label="Описание"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn
            style="margin-bottom: 20px"
            elevation="2"
            small
            color="secondary"
            @click="addExperience"
            >Добавить рабочее место</v-btn
          >
          <h3>Фотографии</h3>
          <v-row>
            <v-col cols="12" sm="4">
              <v-file-input
                v-model="images"
                label="Загрузить фотографии"
                counter
                multiple
                accept="image/*"
                prepend-icon="mdi-camera"
                return-object
                @change="onPhotoChange"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="image in announcement.images"
              :key="image.name"
              class="d-flex child-flex"
              cols="4"
            >
              <v-img
                v-if="image.link"
                :src="image.link"
                aspect-ratio="1"
                class="grey lighten-2"
              />
              <v-img
                v-else
                :src="getImage(image)"
                aspect-ratio="1"
                class="grey lighten-2"
              />
            </v-col>
          </v-row>

          <h3>Контактные данные</h3>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.phoneNumber"
                label="Номер телефона"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.email"
                label="Электроная почта"
                type="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="announcement.secondPhoneNumber"
                label="Номер телефона"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
          <h3>Местоположение</h3>
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="selectedRegion"
                :items="regions"
                chips
                label="Выбрать регион"
                item-text="region"
                return-object
                solo
                @change="loadCitiesByRegion"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="announcement.cityId"
                :items="cities"
                chips
                label="Выбрать город"
                item-text="city"
                item-value="id"
                solo
              ></v-select>
            </v-col>
          </v-row>

          <v-btn
            v-if="isShowSaveButton"
            depressed
            color="primary"
            @click="save"
          >
            Сохранить
          </v-btn>
        </va-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/regions";

export default {
  name: "CreateFormVacancy",
  components: {},
  props: {
    section: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sections: [
        {
          id: 1,
          name: "special-equipment",
          label: "Спецтехника",
        },
        { id: 2, name: "material", label: "Материалы" },
        { id: 3, name: "vacancy", label: "Операторы/Водители" },
      ],
      selectedDate: null,
      selectedCategory: null,
      selectedSubcategory: null,
      selectedRegion: null,
      selectedCity: "",
      marksList: null,
      filters: [],

      selectedEmployment: null,
      selectedPosition: null,
      selectedRight: null,
      selectedTechnician: null,
      selectedWorkExperience: null,
      selectedVailability: null,
      // selects
      workExperience: [
        { id: 1, title: "Нет опыта" },
        { id: 2, title: "1-3 года" },
        { id: 3, title: "более 3-х лет" },
      ],
      vailability: [
        { id: 1, bool: false, title: "Нет в наличии" },
        { id: 2, bool: true, title: "Есть в наличии" },
      ],

      announcement: {
        phoneNnumber: null,
        secondPhoneNumber: null,
        email: null,
        cityId: null,
        title: null,
        description: "",
        images: [],
        filters: [],
        filtersCustom: {},
        positionId: null,
        technicianId: null,
        rightId: null,
        employmentId: null,
        availabilityOfEquipment: null,
        experience: [
          {
            company: "",
            position: "",
            workPeriod: "",
            "work-period": {
              from: "",
              to: "",
            },
            employment_type: "",
            description: "",
          },
        ],
      },

      unitList: [],
      images: [],
      dates: [],
      menu: false,
      categories: null,
    };
  },

  computed: {
    ...mapState("regionsModule", ["cities", "regions"]),

    selectedSection() {
      return this.sections.find((s) => s.id === 3);
    },

    employment() {
      return this.categories?.employment;
    },
    position() {
      return this.categories?.position;
    },
    right() {
      return this.categories?.right;
    },
    technician() {
      return this.categories?.right;
    },

    isShowSaveButton() {
      return this.action !== "show";
    },
  },
  watch: {
    regions: {
      deep: true,

      handler() {
        this.selectedRegion = this.regions.find(
          (e) => e.region == this.item.location.region
        );
        console.log("selectedRegion", this.selectedRegion);
        this.loadCities(this.selectedRegion.id).then(() => {
          this.selectedCity = this.cities.find(
            (c) => c.id === this.item?.city_id
          );
        });
      },
    },
    item: {
      deep: true,
      handler() {
        if (this.item) {
          // this.initDefaultValues();
          this.setItem();
        } else {
          this.announcement = {
            phoneNnumber: null,
            secondPhoneNumber: null,
            email: null,
            cityId: null,
            title: null,
            description: "",
            images: [],
            filters: [],
            filtersCustom: {},
            positionId: null,
            technicianId: null,
            rightId: null,
            employmentId: null,
            availabilityOfEquipment: null,
            experience: [
              {
                company: "",
                position: "",
                workPeriod: "",
                "work-period": {
                  from: "",
                  to: "",
                },
                employment_type: "",
                description: "",
              },
            ],
          };
        }
      },
    },
  },
  mounted() {
    this.initDefaultValues();

    // this.editAnnouncement();
    if (this.item) {
      this.setItem();
    }
  },

  methods: {
    ...mapActions("regionsModule", {
      loadRegions: actionTypes.loadRegions,
      loadCities: actionTypes.loadCities,
    }),
    initDefaultValues() {
      // this.selectedSection = this.sections.find(s=>s.name == this.section)
      this.$admin.http
        .get(`/${this.selectedSection.name}/categories`)
        .then((response) => {
          this.categories = response.data.data;
          console.log("categories", this.categories);
          if (this.item) {
            this.selectedCategory = this.categories.find(
              (c) => c.id === this.item?.category_id
            );
            if (this.item?.subcategory_id) {
              this.selectedSubcategory =
                this.selectedCategory?.subcategories.find(
                  (sc) => sc.id === this.item.subcategory_id
                );
            }
            this.getFilters();
          }
        });
      this.loadRegions().then(() => {
        if (this.item) {
          this.selectedRegion = this.regions.find(
            (r) => r.id === this.item?.region_id
          );
          this.loadCities(this.selectedRegion.id).then(() => {
            this.selectedCity = this.cities.find(
              (c) => c.id === this.item?.city_id
            );
          });
          // this.loadCitiesByRegion().then((cities) => {
          //   this.selectedCity = cities.find((c) => c.id === this.item.city_id);
          // });
        }
      });
      // this.$admin.http.get("/regions").then((response) => {
      //   this.regions = response.data.data.regions;
      // });
    },
    setItem() {
      this.selectedRegion = this.announcement = {
        phoneNumber: this.item.phone_number,
        secondPhoneNumber: this.item.second_phone_number,
        email: this.item.email,
        price: this.item.price,
        title: this.item.title,
        description: this.item.description,
        images: this.mapImagesToObject(this.item.images),
        cityId: this.item.location.id,
        positionId: this.item.position_id,
        technicianId: this.item.technician_id,
        rightId: this.item.right_id,
        employmentId: this.item.employment_id,
        availabilityOfEquipment:
          this.item.availability_of_equipment === 1
            ? (this.announcement.availabilityOfEquipment = true)
            : (this.announcement.availabilityOfEquipment = false),
        experience: this.item.experience,
      };
      if (this.item?.filters) {
        this.announcement.filters = this.item?.filters?.map((e) => e.id);
      }
    },

    save() {
      console.log("announcement", this.announcement);
      console.log("mapAnnouncment", this.mapAnnouncment());
      if (!this.item || this.action === "create") {
        this.$emit("save", {
          route: `/vacancy/announcement`,
          data: this.mapAnnouncment(),
        });
        return;
      }

      this.$emit("update", {
        route: `/vacancy/announcement/${this.item.id}`,
        data: this.mapAnnouncment(),
      });
    },
    mapImagesToObject(arr) {
      const images = arr.map((imageUrl) => {
        return { link: imageUrl };
      });
      return images;
    },
    mapAnnouncment() {
      this.announcement.experience.forEach((e) => {
        e["work-period"] = {
          from: e.workPeriod.slice(0, 10),
          to: e.workPeriod.slice(12),
        };
      });
      const announcement = {
        // filters: Object.values(this.announcement.filtersCustom).filter(
        //   (f) => f !== "undefined"
        // ),
        title: this.announcement.title,
        description: this.announcement.description,
        position_id: this.announcement.positionId,
        employment_id: this.announcement.employmentId,
        technician_id: this.announcement.technicianId,
        right_id: this.announcement.rightId,
        city_id: this.announcement.cityId,
        phone_number: this.announcement.phoneNumber,
        second_phone_number: this.announcement.secondPhoneNumber,
        email: this.announcement.email,
        images: this.announcement.images,
        experience: this.announcement.experience,
        availability_of_equipment: this.announcement.availabilityOfEquipment,
      };

      return announcement;
    },

    selectCategory() {
      this.selectedSubcategory = null;
      this.getFilters();
    },
    selectSubcategory() {
      this.getFilters();
    },

    changeElements(elementId) {
      console.log(elementId);
      const foundElementIndex = this.announcement.filters.findIndex(
        (e) => e === elementId
      );
      if (foundElementIndex === -1) {
        this.announcement.filters.push(elementId);
        return;
      }
      this.announcement.filters.splice(foundElementIndex, 1);
    },

    onPhotoChange() {
      this.images.forEach((file) => {
        this.convertBase64(file);
      });
    },
    convertBase64(file) {
      const ext = file.name.split(".")[1];
      const reader = new FileReader();
      reader.onload = () => {
        this.announcement.images.push({ [ext]: reader.result });
      };
      reader.readAsDataURL(file);
    },
    getImage(file) {
      return file["jpg"] || file["png"] || file["svg"] || file["gif"];
    },
    loadCitiesByRegion() {
      console.log("CITES");
      this.loadCities(this.selectedRegion?.id);
    },
    getFilters() {
      let filterParamName =
        "category_" + this.selectedSection?.name?.replace(/-/gi, "_");
      let query = { [filterParamName]: this.selectedCategory?.id };

      if (this.selectedSubcategory) {
        filterParamName =
          "subcategory_" + this.selectedSection?.name?.replace(/-/gi, "_");
        query = { [filterParamName]: this.selectedSubcategory.id };
      }

      this.$admin.http
        .get(`/announcement/filters`, { params: query })
        .then((response) => {
          this.filters = response.data.data;
          if (this.item) {
            this.filters.forEach((el) => {
              this.announcement.filtersCustom[el.id] = this.item?.filters?.find(
                (f) => f.id === el.id
              )?.value?.id;
            });
          }
        });
    },

    addExperience() {
      this.announcement.experience.push({
        uid: Math.random(),
        company: "",
        position: "",
        workPeriod: "",
        "work-period": {
          from: "",
          to: "",
        },
        employment_type: "",
        description: "",
      });
    },
    // editAnnouncement() {
    //   console.log(this.item);
    //   this.announcement = {
    //     phoneNnumber: this.item.phone_number,
    //     secondPhoneNumber: this.item.second_phone_number,
    //     email: this.item.email,
    //     cityId: this.item.location.id,
    //     title: this.item.title,
    //     description: this.item.description,
    //     images: this.item.images,
    //     positionId: this.item.position_id,
    //     technicianId: this.item.technician_id,
    //     rightId: this.item.right_id,
    //     employmentId: this.item.employment_id,
    //     availabilityOfEquipment: this.item.availability_of_equipment,
    //     experience: this.item.experience,
    //   };
    // },
  },
};
</script>
